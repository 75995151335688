import { dateStrToDateNoTZ, dateToTimeslot, timeSlotToDate, twentyFourHourInMinutes } from '@/utils/date-utils';
import { ButtonGrid } from '@/components/ButtonGrid/ButtonGrid';
import { useDefaultDhpParams, useBooking } from '@/hooks/useBooking';
import { TimeslotCard } from '@/components/TimeslotCard/TimeslotCard';
import { WizardStep } from '@/types/WizardBooking';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { isBefore, isSameDay } from 'date-fns';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { useQuery } from '@apollo/client';
import { GetRestaurantServiceTimesDocument } from '@/graphql/types.generated';
import { Divider } from '@/components/Divider/Divider';
import { SkeletonRect } from '@/components/SkeletonRect/SkeletonRect';
import { useRestaurantDate } from '@/contexts/RestaurantDateProvider/RestaurantDateProvider';
import * as S from './TimeslotSelector.styles';

const DELAY_15_MIN = 15;
const DELAY_30_MIN = 30;

// Potential maximum number of time slots until 5:00 AM tomorrow.
const NB_SLOTS_FOR_30_MIN_DELAY = (twentyFourHourInMinutes + 300) / DELAY_30_MIN;
const NB_SLOTS_FOR_15_MIN_DELAY = NB_SLOTS_FOR_30_MIN_DELAY * 2;

enum DayPartEnum {
  BREAKFAST,
  LUNCH,
  DINNER,
}

const dayParts = [
  {
    name: DayPartEnum.DINNER,
    times: [0, 5 * 60 - 1], // 0:00 - 4:59
  },
  {
    name: DayPartEnum.BREAKFAST,
    times: [5 * 60, 11.5 * 60 - 1], // 5:00 - 11:29
  },
  {
    name: DayPartEnum.LUNCH,
    times: [11.5 * 60, 17 * 60 - 1], // 11:30 - 16:59
  },
  {
    name: DayPartEnum.DINNER,
    times: [17 * 60, (24 + 5) * 60], // 17:00 - 05:00
  },
];

export const filterNoPastTimeSlots = (timeSlotTime: number, isDateToday: boolean, currentTimeSlot: number) => {
  return !isDateToday || timeSlotTime > currentTimeSlot;
};

export const filterRestaurantServiceTimes = (timeSlotTime: number, serviceTimeSlots: Array<number>) => {
  const hasRestaurantServiceTimes = Boolean(serviceTimeSlots.length);
  return hasRestaurantServiceTimes ? serviceTimeSlots.includes(timeSlotTime) : true;
};

export const TimeslotSelector: React.FC = () => {
  const { handleSelectStep, bookingParams, restaurantUuid } = useBooking();
  const { widgetConfig } = useWidgetConfig();
  const { restaurantDate } = useRestaurantDate();
  const defaultDhpParams = useDefaultDhpParams();
  const selectedTimeSlotRef = useRef<HTMLDivElement>(null);

  const bookingDate = dateStrToDateNoTZ(bookingParams.date ?? defaultDhpParams.date);
  const currentTimeslot = dateToTimeslot(restaurantDate);
  const isBookingDateToday = isSameDay(bookingDate, restaurantDate);
  const isBookingDatePast = isBefore(bookingDate, restaurantDate) && !isBookingDateToday;

  const {
    data: restaurantServiceTimesData,
    loading,
    error,
  } = useQuery(GetRestaurantServiceTimesDocument, {
    variables: {
      restaurantUuid: String(restaurantUuid),
      date: bookingParams.date || defaultDhpParams.date,
    },
  });

  let nextStep = WizardStep.Result;
  if (!bookingParams.pax) {
    nextStep = WizardStep.Pax;
  } else if (!bookingParams.date) {
    nextStep = WizardStep.Date;
  }

  useEffect(() => {
    const scrollTimeout = setTimeout(() => {
      selectedTimeSlotRef.current?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
    }, 500);
    return () => {
      clearTimeout(scrollTimeout);
    };
  }, [selectedTimeSlotRef]);

  if (error) {
    throw new Error(error.message);
  }

  const isDelay15Min = widgetConfig?.restaurant?.delay === DELAY_15_MIN;
  const serviceTimeSlots = restaurantServiceTimesData?.restaurant?.serviceTimes || [];
  let timeSlots: { time: number }[] = [];
  if (!isBookingDatePast && serviceTimeSlots.length) {
    timeSlots = [...Array(isDelay15Min ? NB_SLOTS_FOR_15_MIN_DELAY : NB_SLOTS_FOR_30_MIN_DELAY)]
      .map((_, index) => ({ time: (isDelay15Min ? DELAY_15_MIN : DELAY_30_MIN) * index }))
      .filter((timeSlot) => filterNoPastTimeSlots(timeSlot.time, isBookingDateToday, currentTimeslot))
      .filter((timeSlot) => filterRestaurantServiceTimes(timeSlot.time, serviceTimeSlots));
  }

  return (
    <S.Container>
      {loading &&
        [...Array(2)].map((_, index) => (
          <ButtonGrid key={index}>
            {[...Array(7)].map((_, indexGrid) => (
              <SkeletonRect key={`skeleton-${index}-${indexGrid}`} height={46} />
            ))}
          </ButtonGrid>
        ))}
      {!loading && (
        <>
          {!timeSlots.length && (
            <S.Container>
              <Divider />
              <S.Title variant="title3Light">
                <FormattedMessage
                  id="tf_widget_time_selector_no_available_time_for_date"
                  defaultMessage="No available time slot for this date"
                />
              </S.Title>
              <S.Button isSmall variant="secondary" onClick={() => handleSelectStep({ step: WizardStep.Date })}>
                <FormattedMessage id="tf_widget_find_another_date" defaultMessage="Find another date" />
              </S.Button>
            </S.Container>
          )}
          {!!timeSlots.length &&
            dayParts.map((dayPart, index) => {
              const timeSlotsForDayPart = timeSlots.filter(
                (timeSlot) => timeSlot.time >= dayPart.times[0] && timeSlot.time <= dayPart.times[1],
              );
              if (timeSlotsForDayPart.length === 0) {
                return null;
              }
              return (
                <ButtonGrid key={index}>
                  {timeSlotsForDayPart.map((timeSlot) => {
                    const isActive = bookingParams.time
                      ? timeSlot.time === Number(bookingParams.time)
                      : timeSlot.time === Number(defaultDhpParams.time);
                    return (
                      <div key={timeSlot.time} ref={isActive ? selectedTimeSlotRef : undefined}>
                        <TimeslotCard
                          dataTestId={`time-${timeSlot.time}`}
                          isActive={isActive}
                          handleSelectStep={() => {
                            if (nextStep === WizardStep.Result) {
                              // Keep data when doing a "back" from the result page.
                              handleSelectStep({ time: String(timeSlot.time) }, { replaceHistory: true });
                            }
                            handleSelectStep({ time: String(timeSlot.time), step: nextStep });
                          }}
                          date={timeSlotToDate(timeSlot.time)}
                        />
                      </div>
                    );
                  })}
                </ButtonGrid>
              );
            })}
        </>
      )}
    </S.Container>
  );
};
