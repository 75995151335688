import {
  IconClock,
  IconMagnifyingglass,
  IconPersonClock,
} from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { TimeslotCard } from '@/components/TimeslotCard/TimeslotCard';
import { useBooking, useDefaultDhpParams } from '@/hooks/useBooking';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  dateTimeToDateString,
  timeslotToTimeHHMM,
  formatForGraphQl,
  timeSlotToDate,
  dateStrToDate,
  dateStrToDateNoTZ,
  dateCopy,
  dateTimeToDate,
} from '@/utils/date-utils';
import { WizardStep } from '@/types/WizardBooking';
import {
  GetAvailabilitiesDocument,
  GetAvailabilitiesQuery,
  GetOtherAvailabilitiesDocument,
  GetTimeslotsDocument,
  TimeslotFragment,
} from '@/graphql/types.generated';
import { useQuery } from '@apollo/client';
import { SkeletonRect } from '@/components/SkeletonRect/SkeletonRect';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { Button } from '@/components/Button/Button';
import { isSameDay } from 'date-fns';
import { SisterRestaurantsList } from '@/components/SisterRestaurantsList/SisterRestaurantsList';
import { Divider } from '@/components/Divider/Divider';
import { useRestaurantDate } from '@/contexts/RestaurantDateProvider/RestaurantDateProvider';
import _ from 'lodash';
import * as S from './NoSlotAvailableStep.styles';

export const getEightClosestAvailabilitiesFromDate = (
  availabilities: GetAvailabilitiesQuery['availabilities'],
  dateTarget: Date,
): GetAvailabilitiesQuery['availabilities'] => {
  if (!availabilities) {
    return [];
  }
  return availabilities
    .flatMap((e) => (e ? [e] : [])) // Remove "null" from array (and also from type).
    .sort((a, b) => {
      const msA = dateStrToDate(a.date).getTime();
      const msB = dateStrToDate(b.date).getTime();
      const msDate = dateTarget.getTime();
      return Math.abs(msA - msDate) - Math.abs(msB - msDate);
    })
    .slice(0, 8)
    .sort((a, b) => {
      const msA = dateStrToDate(a.date).getTime();
      const msB = dateStrToDate(b.date).getTime();
      return msA - msB;
    });
};

export type NoSlotAvailableStepProps = {
  waitingListSlot?: TimeslotFragment;
};

export const NoSlotAvailableStep: React.FC<NoSlotAvailableStepProps> = ({ waitingListSlot }) => {
  const intl = useIntl();
  const defaultDhpParams = useDefaultDhpParams();
  const { widgetConfig } = useWidgetConfig();
  const { handleSelectStep, bookingParams, restaurantUuid } = useBooking();
  const { restaurantDate } = useRestaurantDate();

  const restaurantDateTomorrow = dateCopy(restaurantDate).setDate(restaurantDate.getDate() + 1);
  const webMaxDays = widgetConfig?.restaurant?.webMaxDays || 90; // TODO: remove default
  const bookingParamsDate = dateStrToDateNoTZ(bookingParams.date ?? defaultDhpParams.date);
  const otherDatesEndDate = dateCopy(restaurantDate);
  otherDatesEndDate.setDate(otherDatesEndDate.getDate() + webMaxDays);
  const displaySisterVenues = widgetConfig?.restaurant?.isPartOfAGroup && widgetConfig.restaurant.isCrossSellingEnabled;

  // Next available time slots for date.
  const {
    data: nextTimesForDate,
    loading: loadingNextTimesForDate,
    error: errorNextTimesForDate,
    called: calledNextTimesForDate,
  } = useQuery(GetTimeslotsDocument, {
    variables: {
      restaurantUuid: String(restaurantUuid),
      date: String(bookingParams.date),
      partySize: Number(bookingParams.pax),
      includeWaitingList: false,
      timeslotFilter: {
        after: { timeslot: Number(bookingParams.time), included: false },
      },
    },
  });

  // Other available time slots for date.
  const {
    data: otherTimesForDate,
    loading: loadingOtherTimesForDate,
    error: errorOtherTimesForDate,
    called: calledOtherTimesForDate,
  } = useQuery(GetTimeslotsDocument, {
    variables: {
      restaurantUuid: String(restaurantUuid),
      date: String(bookingParams.date),
      partySize: Number(bookingParams.pax),
      includeWaitingList: false,
    },
    skip: !(calledNextTimesForDate && nextTimesForDate?.timeslots.length === 0),
  });

  // Next available days for time slot.
  const {
    data: nextDaysForTime,
    loading: loadingNextDaysForTime,
    error: errorNextDaysForTime,
    called: calledNextDaysForTime,
  } = useQuery(GetOtherAvailabilitiesDocument, {
    variables: {
      restaurantUuid: String(restaurantUuid),
      date: String(bookingParams.date),
      timeslot: Number(bookingParams.time),
      partySize: Number(bookingParams.pax),
      includeWaitingList: false,
    },
  });

  // Other available days for time slot.
  const {
    data: otherDaysForTime,
    loading: loadingOtherDaysForTime,
    error: errorOtherDaysForTime,
    called: calledOtherDaysForTime,
  } = useQuery(GetOtherAvailabilitiesDocument, {
    variables: {
      restaurantUuid: String(restaurantUuid),
      date: formatForGraphQl(restaurantDate),
      timeslot: Number(bookingParams.time),
      partySize: Number(bookingParams.pax),
      includeWaitingList: false,
    },
    skip: !(calledNextDaysForTime && nextDaysForTime?.otherAvailabilities?.length === 0),
  });

  // Other available days.
  const {
    data: otherDays,
    loading: loadingOtherDays,
    error: errorOtherDays,
    called: calledOtherDays,
  } = useQuery(GetAvailabilitiesDocument, {
    variables: {
      restaurantUuid: String(restaurantUuid),
      startDate: formatForGraphQl(restaurantDate),
      endDate: formatForGraphQl(otherDatesEndDate),
      partySize: bookingParams.pax,
    },
    skip: !(
      calledOtherTimesForDate &&
      otherTimesForDate?.timeslots.length === 0 &&
      calledOtherDaysForTime &&
      otherDaysForTime?.otherAvailabilities?.length === 0
    ),
  });

  if (
    errorNextTimesForDate ||
    errorNextDaysForTime ||
    errorOtherTimesForDate ||
    errorOtherDaysForTime ||
    errorOtherDays
  ) {
    throw new Error(
      _.join(
        _.compact([
          errorNextTimesForDate?.message,
          errorNextDaysForTime?.message,
          errorOtherTimesForDate?.message,
          errorOtherDaysForTime?.message,
          errorOtherDays?.message,
        ]),
      ),
    );
  }

  const isLoading =
    loadingNextTimesForDate ||
    loadingNextDaysForTime ||
    loadingOtherTimesForDate ||
    loadingOtherDaysForTime ||
    loadingOtherDays;

  return (
    <div>
      <S.TopContainer>
        <S.Title variant="title2">
          <S.TitleIconWrapper>
            <IconClock fontSize={24} />
          </S.TitleIconWrapper>
          <FormattedMessage
            id="tf_widget_noSlot_time_is_not_available"
            defaultMessage="{time} is not available"
            values={{
              time: intl.formatTime(timeSlotToDate(Number(bookingParams.time))),
            }}
          />
        </S.Title>

        {!!waitingListSlot && (
          <div>
            <S.ButtonContainer>
              <Button
                variant="ghost"
                isSmall
                onClick={() => {
                  const isOffer = !!waitingListSlot.bestOffer?.offerType;
                  return handleSelectStep({
                    date: dateTimeToDateString(waitingListSlot.datetime),
                    time: String(waitingListSlot.time),
                    step: isOffer ? WizardStep.Offer : WizardStep.UserInformation,
                    isWaitingList: true,
                  });
                }}
                data-testid="join-waiting-list-button"
              >
                <IconPersonClock />{' '}
                <FormattedMessage
                  id="tf_widget_noSlot_join_waiting_list"
                  defaultMessage="Join the waiting list for {time}"
                  values={{
                    time: timeslotToTimeHHMM(Number(bookingParams.time)),
                  }}
                />
              </Button>
            </S.ButtonContainer>
          </div>
        )}

        <Divider />

        {isLoading && (
          <div data-testid="loaderSkeleton">
            <div style={{ marginBottom: 15 }}>
              <SkeletonRect height={24} uniqueKey="title" />
            </div>
            <S.StyledButtonGrid>
              {[...Array(7)].map((_, index) => (
                <SkeletonRect key={`skeleton-${index}`} height={46} uniqueKey="buttons" />
              ))}
            </S.StyledButtonGrid>
          </div>
        )}

        {!isLoading && (otherTimesForDate || nextTimesForDate)?.timeslots.length !== 0 && (
          <div>
            <S.Subtitle variant="title3Light" data-testid="other-time-slots-for-date">
              {isSameDay(bookingParamsDate, restaurantDate) ? (
                <FormattedMessage
                  id="tf_widget_noSlot_other_slots_for_today"
                  defaultMessage="Other available time slots for today"
                />
              ) : isSameDay(bookingParamsDate, restaurantDateTomorrow) ? (
                <FormattedMessage
                  id="tf_widget_noSlot_other_slots_for_tomorrow"
                  defaultMessage="Other available time slots for tomorrow"
                />
              ) : (
                <FormattedMessage
                  id="tf_widget_noSlot_other_slots_for_date"
                  defaultMessage="Other available time slots for {date}"
                  values={{
                    date: intl.formatDate(bookingParamsDate, {
                      day: 'numeric',
                      month: 'short',
                    }),
                  }}
                />
              )}
            </S.Subtitle>
            <S.StyledButtonGrid>
              {(otherTimesForDate || nextTimesForDate)?.timeslots.map((timeslot, index) => {
                const isOffer = !!timeslot.bestOffer?.offerType;
                return (
                  <TimeslotCard
                    key={timeslot.datetime}
                    handleSelectStep={() =>
                      handleSelectStep({
                        date: dateTimeToDateString(timeslot.datetime),
                        time: String(timeslot.time),
                        step: isOffer ? WizardStep.Offer : WizardStep.UserInformation,
                      })
                    }
                    date={dateTimeToDate(timeslot.datetime)}
                    offerAvailable={isOffer}
                    dataTestId={`next-slot-${index}`}
                  />
                );
              })}
            </S.StyledButtonGrid>
          </div>
        )}

        {!isLoading && (otherDaysForTime || nextDaysForTime)?.otherAvailabilities?.length !== 0 && (
          <div>
            <S.Subtitle variant="title3Light" data-testid="other-dates-for-time-slot">
              <FormattedMessage
                id="tf_widget_noSlot_other_dates_for_time"
                defaultMessage="Other available dates for {time}"
                values={{
                  time: intl.formatTime(timeSlotToDate(Number(bookingParams.time))),
                }}
              />
            </S.Subtitle>
            <S.StyledButtonGrid>
              {(otherDaysForTime || nextDaysForTime)?.otherAvailabilities?.slice(0, 4).map((timeslot, index) => {
                const isOffer = !!timeslot.bestOffer?.offerType;
                return (
                  <TimeslotCard
                    key={timeslot.date}
                    handleSelectStep={() =>
                      handleSelectStep({
                        date: timeslot.date,
                        time: String(timeslot.time),
                        step: isOffer ? WizardStep.Offer : WizardStep.UserInformation,
                      })
                    }
                    date={dateStrToDateNoTZ(timeslot.date)}
                    offerAvailable={isOffer}
                    dataTestId={`next-date-${index}`}
                    isDate
                  />
                );
              })}
            </S.StyledButtonGrid>
          </div>
        )}

        {!isLoading && calledOtherDays && otherDays?.availabilities?.length !== 0 && (
          <div>
            <S.Subtitle variant="title3Light" data-testid="other-dates">
              <FormattedMessage id="tf_widget_noSlot_other_dates" defaultMessage="Other available dates" />
            </S.Subtitle>
            <S.StyledButtonGrid>
              {getEightClosestAvailabilitiesFromDate(otherDays?.availabilities, bookingParamsDate)?.map(
                (availability, index) => {
                  return availability?.date ? (
                    <TimeslotCard
                      key={`other-day-timeslot-card-${index}`}
                      handleSelectStep={() =>
                        handleSelectStep({
                          date: availability.date,
                        })
                      }
                      date={dateStrToDateNoTZ(availability.date)}
                      dataTestId={`next-date-${index}`}
                      isDate
                    />
                  ) : null;
                },
              )}
            </S.StyledButtonGrid>
          </div>
        )}

        {!isLoading && calledOtherDays && otherDays?.availabilities?.length === 0 && (
          <S.ButtonContainer>
            <S.ButtonFindDate isSmall onClick={() => handleSelectStep({ step: WizardStep.Date })}>
              <IconMagnifyingglass />{' '}
              <FormattedMessage id="tf_widget_noSlot_find_available_date" defaultMessage="Find an available date" />
            </S.ButtonFindDate>
          </S.ButtonContainer>
        )}
      </S.TopContainer>

      {!isLoading && displaySisterVenues && (
        <>
          <S.BottomDividerDiv>
            <Divider />
          </S.BottomDividerDiv>
          <SisterRestaurantsList />
        </>
      )}
    </div>
  );
};
